// import modal from "../../res";
// import showToast from "../resources/utils/showToast";

// import handleFetchErrors from "../";
import standardFetchResponses from "../resources/handler/standardFetchResponses";
// import { confirmUserAction } from '../resources/confirmUserAction';


import showToast from "../resources/utils/showToast";
import getFormData from '../resources/utils/getFormData';
import fetchConfig from "../resources/utils/fetchConfig";
import handleFetchErrors from "../resources/handler/handleFetchErrors";
import {
    isValidEmailAddress,
    isValidIndianMobileNumber,
    isValidString
} from "../../utils/validators";

document.onload = function () {
    console.log(`qprkking`)

    adminLogin();
}();


function adminLogin() {

    const adminLoginForm = document.querySelector(`#admin-login-form`);

    if (adminLoginForm) {

        adminLoginForm.addEventListener(`submit`, (e) => {
            e.preventDefault();

            const loginFormData = getFormData(adminLoginForm),
                missingData = [];


            let isDataPerfect = true;

            if (!isValidString(loginFormData.email)) {
                missingData.push(`email address`);
                isDataPerfect = false;
            } else if (!isValidEmailAddress(loginFormData.email)) {
                showToast.error({ message: `Please enter a valid email` });
                isDataPerfect = false;
            }

            if (!loginFormData.password){
                showToast.error({ message: `Please enter a valid password` });
                isDataPerfect = false;
            }

            if (missingData.length)
                showToast.error({
                    message: `please provide ${missingData.join(`, `)} `,
                });


            if (!isDataPerfect) return;


            fetch(`/admin/login`, {
                ...fetchConfig,
                body: JSON.stringify(loginFormData),
            })
                .then(handleFetchErrors)
                .then(standardFetchResponses.success)
                .catch(standardFetchResponses.error);

        });

    }

}
