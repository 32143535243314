import showToast from '../utils/showToast';
import { JSONResponseStatus } from '../utils/verifications';


const standardFetchSuccessHandler = (res, toastConfig = null) => {

    const config = (toastConfig && (typeof toastConfig === `object`) && !Array.isArray(toastConfig)) ? toastConfig : {};


    if (res.data.redirect_uri) {
        if (res.data.message) {
            setTimeout(() => window.location.href = res.data.redirect_uri, 500);

            if (!JSONResponseStatus(res.status).isSuccess) {
                throw new Error(res.data.message);
            }
            else return showToast.info({
                ...config,
                message: res.data.message
            });
        }
        else {
            return window.location.href = res.data.redirect_uri;
        }
    }
    else if (res.data.message) {
        if (!JSONResponseStatus(res.status).isSuccess) {
            throw new Error(res.data.message);
        }
        else {
            return showToast.info({
                ...config,
                message: res.data.message
            });
        }
    }
}

const standardFetchErrorHandler = (err) => {
    console.log(err)
    return showToast.error({ message: err.message || `Internal Server Error` });
}


export default {
    success: standardFetchSuccessHandler,
    error: standardFetchErrorHandler
}